.sendTestInputContainer {
    min-width: 70px;
    padding: .375rem .25rem .375rem .75rem;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.sendTestInputContainer input {
    border: none;
    width: calc(100% - 40px);
}

.sendTestInputContainer input:focus {
    outline-width: 0;
}

.conversationContainer {
    height: calc(100vh - 280px);
    overflow-y: scroll;
    vertical-align: bottom;

}
@media screen and (min-width: 0px) and (max-width: 768px) {

    .recipientsArea {
        width: 100%;
    }
}
@media screen and (min-width: 769px) {
    .recipientsArea {
        width: 75%;
    }
}
.ellipsis {
    text-overflow: ellipsis;
  
    /* Required for text-overflow to do anything */
    max-height: 60px;
    min-height: 60px;
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    word-wrap: break-word;
  }

@media screen and (min-width: 0px) and (max-width: 1100px) {
    .ellipsis { max-width: 300px; } 
}

@media screen and (min-width: 1100px) {
    .ellipsis { max-width: 650px;
    
        max-width: calc(100vw - 800px);} 
}

.campaignRow {
    cursor: pointer;
}



.campaignInfoContainer {
    max-width: 900px;
}

.cell-w-1 {
    width: 10%
}

.cell-w-2 {
    width: 20%
}

.cell-w-3 {
    width: 30%
}

.rowValidPhone {
    /* background-color: #DDFFDD; */
    color: #3FBC5C;
}

.rowInvalidPhone {
    /* background-color: #FFDDDD; */
    color: #DC3545;
}

.suggestionCard {
    margin-bottom: 30px;
    background-color: '#f8f8f8'
}

.campaignListItemHeader {
    padding-top: 10px;
    padding-bottom: 12px;
    padding-left: 8px;
    border-top: 1px solid #dee2e6 !important;
    background-color: #f8f9fa !important;
}

.campaignRow:hover .campaignListItemHeader {
    background-color: #bee5cf !important;
}

.showAllSuggestionsButton {
    cursor: pointer;
    padding-left: 12px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #bee5cf;
    text-align: center;
    letter-spacing: 0.6px;
}
#-wa-help-center__widget-icon
{
  width: 4rem;
  height: 4rem;
  z-index: 1000 !important;
}

#-wa-help-center__widget-icon:hover  {
  filter: brightness(80%);
}

#-wa-help-center__widget-icon__secondary
{
  width: 3rem;
  height: 3rem;
  z-index: 1000 !important;
}

#-wa-help-center__widget-icon__secondary:hover
{
  filter: brightness(80%);
}
#-wa-kcb-widget-anchor {
  z-index: 1010;
  position: relative;
}

#apt-widget {
  z-index: 1010 !important;
  top: 0 !important;
}

.apt-widget-top-container
{
  width: 100% !important;
  top: 0 !important;
}

.apt-widget {
  box-shadow: none !important;
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
}

.apt-widget-window { box-shadow: none !important }
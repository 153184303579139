.sr-combo-inputs {
    margin: 20px 0;
  }

  .sr-input {
    font-size: 16px;
  }

  .sr-card-element {
    padding-top: 12px;
  }

  .btn {
    font-size: 16px;
  }

.tns-checkout-form__description
{
    font-size: 1rem;
}

.-wa-help-center__navigation-button-container
{
    width: 5.5rem;
}

.-wa-help-center__navigation-button-container-empty
{
    width: 2rem;
}

.-wa-help-center__navigation
{
    bottom: 0;
    transition: bottom 0.3s;
}

.-wa-help-center__nav-button-container__collapsed
{
    bottom: 2.5rem;
    transition: bottom 0.3s;
}
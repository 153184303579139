/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;
$side-bg: #5b6770;

/* Styles */
body {
  font: 1rem 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.5rem;
  color: #4d4d4d;
  min-width: 230px;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 300;
}

td {
	padding: 12px;
}
.navbar-brand img 
{
  padding-top: 5px;
}

#sidebarMenu {
  min-height: 510px;

  a {
      color: #f8f9fa; // text-light
      opacity: 0.8;

      &:hover {
          opacity: 1;
      }

      &[aria-current] {
          color: #fff;
          opacity: 1;
      }
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  #sidebarMenu._close { display: none;}

  #sidebarMenu._open {
    height: 100%;
    box-sizing: border-box;
    overflow:  visible;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 300px;
    align-items: flex-start;
    background-color: $side-bg;
  }
  #menuShading { display: none;}
  #menuShading._open {
    background-color: #000000;
    opacity: 0.2;
    z-index: 999;
    position: absolute;
    top:0px;
    left:300px;
    width: calc(100% - 300px);
    height: 500%;
    display: inline-block;
    overflow: hidden;
  }
  #burger {
    position: fixed;
    left: 0;
    top: 0;
    padding: 3px;
    margin: 7px;
    z-index: 901;
  }

  .fixedHeader {
    position: fixed;
    left: 0px;
    top: 0px;
    padding-left: 55px;
    background-color:#F8F8F8;
    display: block;
    width: 100%;
    z-index: 900;
    height: 55px;
    word-wrap: none;
    white-space: nowrap;
  }

  .hideOnMobile {
    display: none;
    height: 100px;
    background-color: red;
  }

  .mainContent {
    margin-left: 0;
    margin-top: 45px;
    padding: 20px;
  }

  .globalNotification {
    padding: 10px;
    margin-top: 55px;
    margin-bottom: -85px;
  }

  .modal-75w { max-width: 95% !important; }

  .closeNavigationButton {
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 260px;
    padding: 3px;
    margin: 6px;
    color: #666666;
  }

  .statValuesBlock {

  }
}

@media screen and (min-width: 769px) {
  #burger { display: none;}
  #sidebarMenu {
    height: 100%;
    box-sizing: border-box;
    overflow:  visible;
    position: fixed;
    z-index: 1000;
    width: 300px;
    align-items: flex-start;
    background-color: $side-bg;
  }
  #menuShading { display: none; }

  .hideOnDesktop {
    display: none;
  }
  .mainContent {
    margin-left: 300px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 38px;
  }
  .globalNotification {
    margin-left: 300px;
    padding: 20px;
    margin-bottom: -65px;
  }

  .modal-75w { max-width: 75% !important; }
  .closeNavigationButton { display: none;}
  .statValuesBlock {
    width: 236px
  }
}

.mimicFormControl {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    word-wrap: break-word;
}

.mimicFormControlReadOnly {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #eee;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  word-wrap: break-word;
}

.mimicFormControlInline {
  display: inline-block;
  //width: 100%;
  min-width: 70px;
  text-align: right;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.mimicFormControlInlineReadonly {
  display: inline-block;
  //width: 100%;
  min-width: 70px;
  text-align: right;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #eee;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.colorBlock {
  display: inline-block;
  //width: 100%;
  min-width: 40px;
  text-align: right;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.navbar-nav a:hover {
  text-decoration: none;
}

.modal-90w { max-width: 90% !important; }

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  transform: scale(1.5);
  padding: 10px;
  margin-top: 6px;
}



.popover {
  max-width: 700px;
}

.form-check-label {
  font-size: 18px;
}

label {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem;
  color: #6c757d !important;
}

.inlineLabel {
  display: inline-block;
  width: 165px;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem;
  color: #6c757d !important;
}

.fontSize1rem {
    font-size: 1rem;
}

input.form-control::placeholder {
    color: #ADB5BD;
}


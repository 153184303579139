.-wa-help-center__header__collapsed
{
    height: 5rem;
    transition: height .3s;
}
.-wa-help-center__header
{
    height: 10rem;
    transition: height .3s;
}

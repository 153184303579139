.-wa-help-center__bottom-right
{
    position: fixed;
    right:0;
    bottom: 0;
}

.-wa-help-center__bottom-right-secondary
{
    position: fixed;
    right:0;
    bottom: 6rem;
}

.-wa-help-center__body::-webkit-scrollbar {
    width: .75rem;
}
.-wa-help-center__body::-webkit-scrollbar-track {
    background: #e5e5e5;
    border-radius: 10px;
}
.-wa-help-center__body::-webkit-scrollbar-thumb {
    background: #c3c3c3;
    border-radius: 10px;
}

.-wa-help-center__body::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.cursor-pointer
{
    cursor: pointer;
}

.-wa-help-center-z1
{
    z-index: 1000 !important;
}

.-wa-help-center-z2
{
    z-index: 1001 !important;
}

.-wa-help-center-z3
{
    z-index: 1002 !important;
}


@keyframes wa-help-center__show-badge {
    0% {
        transform: translateY(-4rem);
    }
    100% {
        transform: translateY(0);
    }
}

.wa-help-center__show-badge {
    animation: .4s rollout;
}
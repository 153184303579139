.tns-global-notification__register-container {
    font-size: 1.25rem;
    background: linear-gradient(89.9deg, #5B6770 0.03%, #487D9E 14.6%, #2B93D1 33.34%, #5A4D63 82.26%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    color: white;
    margin-left: 350px;
    margin-right: 50px;
    padding: 20px;
}

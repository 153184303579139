.text-reply-settings__description
{
    margin-bottom: 2.5rem;
}
.text-reply-settings__alert_disabled
{
    width: 37rem;
}
.text-reply-settings__alert_enabled
{
    width: 43rem;
}


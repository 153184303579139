.-wa-help-center__main-container
{
  width: 32rem;
  z-index: 1001;
}

.waHelpContainer-enter
{
  transform: translateX(101%);
}

.waHelpContainer-enter-active
{
  opacity: 0.5;
  transition: transform 300ms ease-in-out;
  transform: translateX(0);
}

.waHelpContainer-exit
{
  opacity: 1;
  transform: translateX(0);
}

.waHelpContainer-exit-active
{
  opacity: 0.5;
  transition: transform 300ms ease-in-out;
  transform: translateX(101%);
}

.waHelpContainer-exit-done
{
  display: none;
  opacity: 0;
}
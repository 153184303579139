.tabContent {
    padding-top: 25px;
    max-width: 800px;
}
.settingCard {
    max-width: 700px;
    min-width: 300px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.-wa-help-center__navigation-button
{
    width: 2.5rem;
    height: 2.5rem;
}

.-wa-help-center__navigation-button__disabled
{
    opacity: 0.5;
}

.-wa-help-center__navigation-button:hover
{
    -webkit-filter: brightness(80%);
}

.-wa-help_nav-active
{
    background: rgba(0,0,0,.25);
}
.-wa-help-center__task-bar
{
    z-index: 1003;
}

.-wa-help-center__back-button
{
    top: 0rem;
    transition: top 0.3s;
}

.-wa-help-center__back-button__collapsed
{
    top: 1rem;
    transition: top 0.3s;
}
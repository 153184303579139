#-wa-help-center__chat-widget-icon
{
  width: 4rem;
  height: 4rem;
  z-index: 1000 !important;
}

.-wa-help-center__chat-widget-icon__unread-message
{
  width: 1.5rem;
  height: 1.5rem;
  right: 1.5rem;
  bottom: 3.5rem;
  z-index: 1001 !important;
}

#-wa-help-center__chat-widget-icon:hover
{
  filter: brightness(80%);
}
